.chatbot-container {
  position: relative;
  z-index: 999999999;

  .toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, #7b4ffa 0%, #e64579 47.5%, #f4aa41 100%)
        border-box;
    border-radius: 25px;
    border: 2px solid transparent;
    padding: 10px;
    cursor: pointer;

    .button-text {
      font-family: Inter;
      background: -webkit-linear-gradient(
        #7b4ffa 0%,
        #e64579 47.5%,
        #f4aa41 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .chatbot-popup {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 450px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: rgba(38, 38, 38, 1);
    overflow: hidden;
    transform-origin: bottom right;
    transform: scale(0);
    transition: transform 0.3s ease;

    &.open {
      transform: scale(1);
      max-height: 80vh;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      overflow: auto;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 80px;

      .heading {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
      }

      .subheading {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(219, 219, 219, 1);
        text-align: center;
      }
    }

    .chat-container {
      padding: 24px 20px;
      overflow: auto;
    }

    .message-list {
      .message {
        padding: 5px 10px;
        margin-bottom: 5px;
        border-radius: 5px;

        .message-text {
          overflow-wrap: anywhere;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          border-radius: 10px;
          padding: 10px 24px;
          background-color: rgba(54, 54, 54, 1);
          color: rgba(255, 255, 255, 1);
        }

        &.left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          width: 80%;
        }

        &.right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 8px;
        }
      }
    }
  }

  .title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
    padding: 16px;
  }

  .input-wrapper {
    padding: 24px 20px;

    .input-container {
      display: flex;
      align-items: center;
      background-color: rgba(54, 54, 54, 1);
      border-radius: 99px;
      padding: 4px 8px;
      gap: 6px;

      input {
        padding: 0px 4px;
        border: none;
        height: 34px;
        border-radius: 99px;
        width: 100%;
        outline: none;
        box-shadow: none;
        background-color: rgba(54, 54, 54, 1);
        color: rgba(255, 255, 255, 1);

        &:-webkit-autofill {
          background-color: rgba(54, 54, 54, 1);
          -webkit-box-shadow: 0 0 0px 1000px rgba(54, 54, 54, 1) inset;
          -webkit-text-fill-color: rgba(255, 255, 255, 1);
        }
      }

      .send-button {
        padding: unset;
        height: unset;
        outline: none;
        min-height: unset;
        background: unset;
      }
    }
  }
}

.loading-dots {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #aaa;
  margin: 0 4px;
  animation: dot-animation 1s infinite linear;
}

@keyframes dot-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(0);
  }
}

.chat-container {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #202020;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
}

.suggestions-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 24px 20px 0px 20px;
}

.custom-form {
  width: 90%;
}

.custom-avatar {
  background-color: rgba(155, 89, 182, 1);
  color: #fff;
}

.custom-ai-logo {
  background-color: rgba(250, 250, 250, 1);
}
