.suggestions {
  font-family: Inter;
  color: rgba(202, 157, 14, 1);
  border: 1px solid rgba(202, 157, 14, 1);
  background-color: rgba(53, 47, 28, 1);
  border-radius: 40px;
  padding: 6px;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
